<template>
  <div class="row justify-content-around justify-content-md-between mb-3">
    <div class="titles mb-3 col-12 col-md-3">Consultas</div>
    <div
      class="
        row
        col-12 col-md-9
        align-items-center
        justify-content-between justify-content-md-end
      ">
      <div
        class="d-flex align-items-center justify-content-end col-12 col-md-4">
        <b-form-checkbox
          v-if="hasMultipleClinics"
          class="d-flex align-items-center"
          v-model="isAllClinicsActive"
          @change="emitUpdate()"
          name="checkbox-1">
          <span class="ms-1">Todas las clinicas</span>
        </b-form-checkbox>
      </div>
      <div
        class="
          d-flex
          align-items-center
          justify-content-start justify-content-md-end
          col-6 col-md-3 col-lg-2
        ">
        <select
          v-if="canFilterViewOption"
          class="form-select w-auto inputsGeneralClass"
          v-model="viewOption"
          @change="emitUpdate()">
          <option value="medic">Medicos</option>
          <option value="cacit">Cacit</option>
          <option value="abc">ABC</option>
        </select>
      </div>
      <div class="d-flex align-items-center justify-content-end col-6 col-md-3">
        <date-picker
          v-model="date"
          type="month"
          :formatter="momentFormat"
          :format="'DD/MM/YYYY'"
          @change="emitUpdate()"
          :input-class="'form-control inputsGeneralClass w-100'"
          placeholder="Este Mes"></date-picker>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { mapGetters } from "vuex";

export default {
  name: "Search",
  components: { DatePicker },
  data() {
    return {
      date: null,
      viewOption: "medic",
      isAllClinicsActive: false,
      momentFormat: {
        // [optional] Date to String
        stringify: (date) => {
          return date ? this.$moment(date).format("L") : "";
        },
        // [optional]  String to Date
        parse: (value) => {
          return value ? this.$moment(value, "L").toDate() : null;
        },
        // [optional] getWeekNumber
        getWeek: () => {
          // a number
        },
      },
    };
  },
  watch: {
    getClinic() {
      this.emitUpdate();
    },
  },
  mounted() {
    const setOptionViewByUserType = {
      superadmin: "medic",
      admin: "medic",
      manager: "medic",
      abc: "abc",
      manager_abc: "abc",
      medic: "medic",
      cacit: "cacit",
      manager_cacit: "cacit",
    };
    this.viewOption = setOptionViewByUserType[this.getUserType];
    this.emitUpdate(true);
  },
  computed: {
    ...mapGetters(["getUserType", "hasMultipleClinics", "getClinic"]),
    canFilterViewOption() {
      const canViewMedicGraphics = ["superadmin", "admin", "manager"];
      return canViewMedicGraphics.includes(this.getUserType);
    },
  },
  methods: {
    emitUpdate(onMounted = false) {
      const { date, viewOption, isAllClinicsActive } = this;
      this.$emit(
        "updateData",
        {
          date,
          viewOption,
          isAllClinicsActive,
        },
        onMounted
      );
    },
  },
};
</script>
